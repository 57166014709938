<script lang="ts" setup>
defineProps<{
  project: string
  projectClass?: string
  headline: string
  headlineClass?: string
}>()

const PROJECT_HEADER_TITLE_CLASS = 'project-header-title'

useAnime({
  targets: `.${PROJECT_HEADER_TITLE_CLASS}`,
  translateX: ['-200%', 0],
  opacity: [0, 1],
  easing: 'spring(1, 80, 12, 0)',
})
</script>

<template>
  <h1
    :class="[PROJECT_HEADER_TITLE_CLASS]"
    class="relative flex w-full flex-col italic text-white motion-safe:invisible"
  >
    <span class="text-2xl font-black xl:text-3xl" :class="projectClass">
      {{ project }}
    </span>
    <span
      class="mt-3 text-6xl font-extrabold xl:text-[80px]/[1]"
      :class="headlineClass"
      v-html="headline"
    />
  </h1>
</template>
